import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { graphql, PageProps } from 'gatsby';
import React, { useState, ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  addMessageToCustomerService,
  selectCart,
  updateApiUserCart,
} from '../store/slices';
import { navigate } from 'gatsby';
import { CartItem } from '../store/interfaces';
import { RootState } from '../store';
import { ContentfulSettings } from '../contentful';
import { CartArticleList } from '../components/cart-article-list';
import { Helmet } from 'react-helmet';

interface CartPageQueryProps {
  contentfulSettings: ContentfulSettings;
}

type CartPageType = PageProps<CartPageQueryProps>;
const CartPage = ({ data }: CartPageType) => {
  const cart = useAppSelector((state: RootState) => selectCart(state));
  const dispatch = useAppDispatch();

  const changeCartItemQuantityActionCreator = (
    cartItem: CartItem,
    newOrUpdatedQuantity: number,
    update = true,
  ): void => {
    const updatedCartItem = {
      ...cartItem,
      quantity: update
        ? cartItem.quantity + newOrUpdatedQuantity
        : newOrUpdatedQuantity,
    };
    if (updatedCartItem.quantity === 0) {
      dispatch(
        updateApiUserCart({
          cartItem: updatedCartItem,
          operation: 'REMOVE',
        }),
      );
    } else {
      dispatch(
        updateApiUserCart({
          cartItem: updatedCartItem,
          operation: 'UPDATE',
        }),
      );
    }
  };

  const emptyCartActionCreator = (emptyCart = false) => {
    if (!emptyCart) return;

    const dummyCartItem: CartItem = {
      articleNumber: '',
      articleQuantityAndAreaExplanation: '',
      articleTitle: '',
      quantity: 0,
      articleSalesUnit: '',
    };
    dispatch(
      updateApiUserCart({ cartItem: dummyCartItem, operation: 'EMPTY' }),
    );
    setShowConfirmEmptyCart(false);
  };

  const [message, setMessage] = useState<string>();

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setMessage(value);
  };

  const setMessageInStateAndNavigateToCheckout = () => {
    dispatch(addMessageToCustomerService(message ?? ''));
    navigate('/kassa');
  };

  const [showConfirmEmptyCart, setShowConfirmEmptyCart] =
    useState<boolean>(false);

  const handleContinueEmptyCart = () => {
    emptyCartActionCreator(true);
  };

  const ConfirmEmptyCart = () => {
    const dialogStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      backgroundColor: '#FFF',
      border: '1px solid #000',
      boxShadow: 24,
      padding: '2em',
    };

    return (
      <Modal
        open={showConfirmEmptyCart}
        aria-labelledby="confirm-title"
        aria-describedby="confirm-description"
      >
        <Box sx={dialogStyle}>
          <Typography id="confirm-title" variant="h6" component="h2">
            {data.contentfulSettings.ecomCommon.cartPage.emptyCartTitle}
          </Typography>
          <Typography id="confirm-description" sx={{ mt: 2 }} component="p">
            {data.contentfulSettings.ecomCommon.cartPage.emptyCartBody}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              columnGap: '1em',
              marginTop: '2em',
            }}
          >
            <Button
              variant="outlined"
              size="large"
              onClick={() => setShowConfirmEmptyCart(false)}
            >
              {
                data.contentfulSettings.ecomCommon.cartPage
                  .emptyCartCancelButton
              }
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={handleContinueEmptyCart}
            >
              {data.contentfulSettings.ecomCommon.cartPage.emptyCartOkButton}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <Helmet title={data.contentfulSettings.ecomCommon.cartPage.title} />
      <Typography component="h1" variant="h1" sx={{ my: 4 }}>
        {data.contentfulSettings.ecomCommon.cartPage.title}
      </Typography>

      <CartArticleList
        cartItems={cart}
        ecomCommon={data.contentfulSettings.ecomCommon}
        changeCartItemQuantityActionCreator={
          changeCartItemQuantityActionCreator
        }
        cartIsEditable={true}
      />

      {cart.length > 0 && (
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '100%', maxWidth: '55ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Meddelande till kundtjänst"
            multiline
            rows={4}
            sx={{ backgroundColor: '#fff' }}
            variant="outlined"
            value={message}
            onChange={onChange}
          />
        </Box>
      )}

      <Grid container spacing={2} sx={{ my: 4 }}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-between', sm: 'end' },
            order: { sm: '2' },
          }}
        >
          {cart.length > 0 && (
            <Button
              variant="outlined"
              size="large"
              onClick={() => setShowConfirmEmptyCart(true)}
            >
              {data.contentfulSettings.ecomCommon.emptyCart}
            </Button>
          )}
          {cart.length > 0 && (
            <Button
              variant="contained"
              size="large"
              sx={{ marginLeft: { xs: 'auto', sm: 1 } }}
              onClick={() => setMessageInStateAndNavigateToCheckout()}
            >
              {data.contentfulSettings.ecomCommon.toCheckout}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={4} sx={{ order: { sm: '1' } }}>
          <Button variant="outlined" size="large" onClick={() => navigate('/')}>
            {data.contentfulSettings.ecomCommon.continueShopping}
          </Button>
        </Grid>
      </Grid>
      <ConfirmEmptyCart />
    </>
  );
};

export const query = graphql`
  query {
    contentfulSettings {
      ecomCommon {
        ...EcomCommon
      }
    }
  }
`;

export default CartPage;
